// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Import Custom SB Admin 2 Mixins and Components
@import "mixins.scss";
@import "global.scss";
@import "utilities.scss";

// Custom Components
@import "dropdowns.scss";
@import "navs.scss";
@import "buttons.scss";
@import "cards.scss";
@import "charts.scss";
@import "login.scss";
@import "error.scss";
@import "footer.scss";

//dataTables
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';

//Jquery ui
@import '~jquery-ui/themes/base/all.css';


// Incluir Font Awesome:
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';