// Override Bootstrap default variables here
// Do not edit any of the files in /vendor/bootstrap/scss/!

// Color Variables
// Bootstrap Color Overrides

$white: #fff !default;
$gray-100: #dddfeb !default;
$gray-200: #d1d3e2 !default;
$gray-300: #b7b9cc !default;
$gray-400: #858796 !default;
$gray-500: #6e707e !default;
$gray-600: #5a5c69 !default;
$gray-700: #3a3b45 !default;
$gray-800: #2b2b33 !default;
$gray-900: #191a1f !default;
$black: #000 !default;

$blue: #4e73df !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74a3b !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1DAC05 !default;
$teal: #20c9a6 !default;
$cyan: #36b9cc !default;

// Custom Colors
$brand-google: #ea4335;
$brand-facebook: #3b5998;

// Set Contrast Threshold
$yiq-contrasted-threshold: 195 !default;

// Typography
$body-color: $gray-900 !default;

$font-family-sans-serif: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

$font-weight-light: 300 !default;
// $font-weight-base: 400;
$headings-font-weight: 400 !default;

// Shadows
$box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, .2) !default;
$box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, .15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// Borders Radius
$border-radius: 0.35rem !default;
$border-color: darken($gray-200, 2%);

// Spacing Variables
// Change below variable if the height of the navbar changes
$topbar-base-height: 4.375rem;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 14rem;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 6.5rem;

// Card
$card-cap-bg: $gray-100;
$card-border-color: $border-color;

// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Transitions
$transition-collapse: height .15s ease !default;

// Dropdowns
$dropdown-font-size: 0.85rem;
$dropdown-border-color: $border-color;
